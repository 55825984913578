import React from 'react';

import PageWrap from 'components/PageWrap';

import './404.scss';

import PagesBackgroundImg from 'media/pages/blog/Pages.jpg';
import IndyLink from 'components/IndyLink';

export default function NotFoundPage(){
    return (
        <PageWrap
            title="404 Page Not Found - Mashoom"
            path="/404"
        >
            <div id="Page404Wrap" style={{backgroundImage: "url(" + PagesBackgroundImg + ")"}}>
                <div id="Page404">
                    <h1>Page not found</h1>
                    <p>O dear, we don't have a page here! Click <IndyLink adr="/">here</IndyLink> to return home.</p>
                </div>
            </div>
        </PageWrap>
    );
}